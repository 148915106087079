<template>
  <component
    :is="itemCompTag"
    :ref="`productItem_${item.goods_id}`"
    v-expose="getAnalysisData"
    :style="compStyle"
    :class="[
      {
        'wish-list-comp__itemMore': column !== 1, 
        'wish-list-comp__show-similar-popup': showSimilarRecommend(item),
        'wish-list-comp__crop': cropRateItem === '1-1' || cropRateItem === '3-4',
        'wish-list-comp__show-delete': similarPopupClass[`show-delete_${item.goods_id}`],
        'product-item-ctn_disable': selectStatus && !!item.inGroup
      }
    ]"
    :item="item"
    :index="index"
    :crop-rate="cardImgCropRate"
    :column="column"
    :constant-data="constantData"
    :config="mergedConfig"
    :language="language"
    :show-selling="false"
    :show-select="selectState"
    :show-similar-popup="showSimilarRecommend(item)"
    :select-status="selectStatus"
    :select-disable="!!item.inGroup"
    :show-collect-num="showCollectNum"
    @mounted="itemMounted"
    @slideDone="itemSlideDone"
    @clickMoreBtn="handleMoreBtn"
    @longClick="handleLongClick"
    @select="itemSelect"
    @openQuickAdd="handleOpenQuickAdd"
    @clickAddToBoard="handleAddBoard"
    @clickDelete="clickDelete"
    @clickItem="clickItem"
  >
    <!-- 解决弹窗关闭问题，所以拆成两个单独的实例 -->
    <!-- 实时反馈推荐 -->
    <template v-if="showFeedbackRecommend">
      <RecommendPopup
        v-if="recommendPopupVisible && recommendPopupGood.length > 0"
        slot="imgBottom"
        ref="recommendPopup"
        :constant-data="constantData"
        :language="language"
        :list-abt-result="listAbtResult"
        :shein-club-info="sheinClubInfo"
        :feedback-rec-ccc-config="feedbackRecCccConfig"
        :goods-id="item.goods_id"
        :cat-id="item.cat_id"
        :discount-rate="getDiscountRate"
        :list-data="recommendPopupGood"
        :sale-price="item.salePrice && item.salePrice.usdAmount"
        :scenes="feedbackScenes"
        :ici="ici"
        :source-from="sourceFrom"
        :crop-rate="cropRateItem"
        @openQuickAdd="$emit('openQuickAdd', $event)"
        @recPopupMounted="$emit('recPopupMounted')"
        @operateInRecPopup="$emit('operateInRecPopup')"
      />
    </template>
    <!-- 同类推荐 -->
    <template v-else-if="watSearchSliderVisible">
      <RecommendPopup 
        v-if="watSearchSliderVisible"
        slot="imgBottom"
        :ref="`recommendPopup_${index}`"
        :constant-data="constantData"
        :language="language"
        :list-abt-result="listAbtResult"
        :feedback-rec-config="feedbackRecConfig"
        :cur-data="item"
        :goods-id="item.goods_id"
        :cat-id="item.cat_id"
        :scene-id="3"
        :scenes="scenes"
        :shein-club-info="sheinClubInfo"
        :is-boards="true"
        :crop-rate="cropRateItem"
        @recPopupMounted="showRecPopup(item, index)"
      />
    </template>
    <!-- 相似推荐 -->
    <template v-if="showSimilarRecommend(item)">
      <RecommendPopup 
        slot="imgBottom"
        :ref="`recommendPopupB_${index}`"
        :list-data="similarRecommendData?.[item.goods_id]?.length ? similarRecommendData?.[item.goods_id] : item?.similar_products"
        :show-close-icon="false"
        :is-find-similar="true"
        :constant-data="constantData"
        :language="language"
        :shein-club-info="sheinClubInfo"
        :list-abt-result="listAbtResult"
        :disable-similar-add="disableSimilarAdd"
        :feedback-rec-config="feedbackRecConfigB"
        :cur-data="item"
        :is-suggested="isSuggested(item)"
        :goods-id="item.goods_id"
        :cat-id="item.cat_id"
        :scene-id="18"
        :scenes="scenes"
        :crop-rate="cropRateItem"
        @recPopupMounted="showRecPopup(item, index)"
      />
    </template>
    <ReducePriceLabel
      v-if="getReducePrice({ item })"
      slot="reducePrice"
      :text="getReducePrice({ item })"
    />
    <StockLeft
      v-if="stockText"
      slot="stockLeft"
      :stock-text="stockText"
    />
    <!-- web中间层 返回用 -->
    <ProductLabelCarousel 
      v-if="labelCarouselInfo.showWeb"
      slot="labelCarousel"
      :data="item"
      :sort-labels="sortLabels"
      :language="language"
      :icon-color="'#A86104'"
      :icon-size="12"
      :offset-top="0.5"
      :text-width="130"
      :color="'#A86104'"
      :height="14"
    />

    <!-- java 中间层返回用 -->
    <BffProductLabelCarousel
      v-else-if="labelCarouselInfo.showBff"
      slot="labelCarousel"
      :goodsInfo="item"
      :language="language"
    />
    <VisitorLabel
      v-if="stockText && hasVisitorLabel({ item }) && config.showItemV3"
      slot="imgTopLeft"
      :text="getVisitorText({ item })"
    />
    <WishNum
      v-if="getItemCollection({ item })"
      slot="wishNum"
      :item-collection="getItemCollection({ item })"
    />
    <MoreInfoBtn
      slot="moreInfoBtn"
    />
  </component>
</template>

<script>
/* components */ 
import OneRowItem from './OneRowItem/item.vue'
import MoreRowItemre from 'public/src/pages/components/product/item_v2/Item.vue'
import ItemV3 from 'public/src/pages/components/product/item_v3/MultipleImageRowCard.vue'
import { closestRatioHeight } from 'public/src/pages/goods_detail/utils/common.js'
import {
  ReducePriceLabel,
  getReducePriceLabel,
} from 'public/src/pages/components/product/item_v3/components/ProductCardSlots/ReducePriceLabel'
import {
  StockLeft,
  getStockText,
} from 'public/src/pages/components/product/item_v3/components/ProductCardSlots/StockLeft'
import {
  VisitorLabel,
  hasVisitorLabel,
  getVisitorText,
} from 'public/src/pages/components/product/item_v3/components/ProductCardSlots/VisitorLabel'
import BffProductLabelCarousel from 'public/src/pages/components/product/item_v3/components/ProductCardSlots/ProductLabelCarousel/BffProductLabelCarousel.vue'
import { ProductLabelCarousel, getLabelCarousel } from 'public/src/pages/components/product/item_v3/components/ProductCardSlots/ProductLabelCarousel'
import { WishNum, getItemCollection } from 'public/src/pages/components/product/item_v3/components/ProductCardSlots/WishNum'
import { MoreInfoBtn } from 'public/src/pages/components/product/item_v3/components/ProductCardSlots/MoreInfoBtn'

/* utils */ 
import analysis from './analysis.js'
const { GB_cssRight, LAZY_IMG, IS_RW, lang, langPath, LAZY_IMG_PIXEL, LAZY_IMG_SOLID_COLOR, PUBLIC_CDN, IMG_LINK } = gbCommonInfo

export default {
  name: 'WishGroupProductItem',
  components: {
    OneRowItem,
    MoreRowItemre,
    ItemV3,
    ReducePriceLabel,
    StockLeft,
    VisitorLabel,
    MoreInfoBtn,
    WishNum,
    ProductLabelCarousel,
    BffProductLabelCarousel,
    RecommendPopup: () => import(/* webpackChunkName: "plv2_RecommendPopup" */'public/src/pages/product_list_v2/components/CardRecommend/RecommendPopup.vue'),
  },
  props: {
    similarRecommendData: {
      type: Object,
      default: () => ({}),
    },
    column: {                    // 一行几图（一图、两图、三图）
      type: Number,
      default: 2
    },
    item: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      default: 0,
    },
    config: {
      type: Object,
      default: () => ({})
    },
    inWaterFall: {
      type: Boolean,
      default: false,
    },
    language: {
      type: Object,
      default: () => ({})
    },
    itemMounted: {
      type: Function,
      default: function() {},
    },
    similarPopupClass: {
      type: Object,
      default: () => ({}),
    },
    handleLongClick: {
      type: Function,
      default: function() {},
    },
    handleOpenQuickAdd: {
      type: Function,
      default: function() {},
    },
    handleAddBoard: {
      type: Function,
      default: function() {},
    },
    clickDelete: {
      type: Function,
      default: function() {},
    },
    clickItem: {
      type: Function,
      default: function() {},
    },
    itemSelect: {
      type: Function,
      default: function() {},
    },
    listAbtResult: {
      type: Object,
      default: () => ({}),
    },
    selectState: Boolean,       // 编辑状态
    selectStatus: {
      type: Boolean,
      default: false,
    },
    showCollectNum: Boolean,    // 显示收藏数（一行一图）
    disableSimilarAdd: { // 给同类推荐半屏列表用， 禁止半屏列表内二次触发同类推荐弹窗
      type: Boolean,
      default: false
    },
    saveTotal: { // 没筛选的总收藏数
      type: Number,
      default: 0
    },
    showFeedbackRecommend: { // 是否优先展示实时反馈推荐
      type: Boolean,
      default: false
    },
    feedbackRecCccConfig: { // 实时反馈推荐ccc配置信息
      type: Object,
      default: () => ({}),
    },
    // 传给实时反馈弹窗的数据, pageId=1308184808
    recommendPopupGood: {
      type: Array,
      default: () => ([]),
    },
    sheinClubInfo: {
      type: Object,
      default: () => ( {} )
    }
  },
  data() {
    return {
      constantData: {
        GB_cssRight,
        LAZY_IMG,
        IS_RW,
        lang,
        langPath,
        LAZY_IMG_PIXEL,
        LAZY_IMG_SOLID_COLOR,
        PUBLIC_CDN,
        IMG_LINK
      },
      scenes: 'WishlistsoldoutSimilar',
      feedbackScenes: 'WishlistReco',
      productList: [],
      goodsItemInfo: null,
      allSelectTool: {},
      allStateSign: false,
      selectItemsGoods: [],
      selectLimitNum: 100,
      empty: false,
      pullDisabled: false,
      isShowRecommendSimilarAdd: false,
      recommendPopupVisible: false,
      sourceFrom: 'wishlist',
      ici: 'page_collection_boards',
      cropRateItem: '', // 商品图片比例
      feedbackRecConfigB: {
        productImpressions: 10,
        isWishlist: true,
        labelLang: this.language.SHEIN_KEY_PWA_26215,
        type: '',
      }, // 相似推荐配置
      feedbackRecConfig: {
        productImpressions: 10,
        isWishlist: false,
        labelLang: this.language.SHEIN_KEY_PWA_26766,
        type: 'similar_items_added',
      }, // 同类推荐配置
      sortLabels: [
        (item) => {
          const text = item?.pretreatInfo?.stockLeft
          if (!text) return ''
          return {
            icon: 'warn',
            key: '-1',
            text
          }
        },
        '3526',
        '3527',
        '1661',
        '207',
        '3460',
        '3457',
        '3726',
        '3454'
      ]
    }
  },
  computed: {
    getDiscountRate() {
      return this.item.pretreatInfo?.discountInfo?.originDiscountRate || 0
    },
    mergedConfig () {
      if (this.inWaterFall) {
        const fromSimilarItemAdd = Number(this.item?.is_on_sale) === 0 || Number(this.item?.stock) === 0

        return {
          ...this.config,
          autoCropImage: true, // 开启方图
          // 开启feedback相关，用于从商详回来拿到sesstion中的信息
          urlQuery: {
            // 展示实时反馈推荐, 售罄商品不接实时反馈记录
            showFeedbackRec: this.disableSimilarAdd ? 0 : Number(!fromSimilarItemAdd),
          },
        }
      }
      return this.config
    },
    // 相似推荐和同类推荐都会有反馈半弹层，且有同时存在的情况
    watSearchSliderVisible() {
      // 解析abt
      let { WishlistSimilarcomparison } = this.listAbtResult
      let showWishlistSimilarType = WishlistSimilarcomparison?.p?.Similarcomparison
      // 同类推荐展示条件
      // 非半屏列表 && 未售罄 && abt打开 && 两列 && 总数大于10 && shein && 
      let configFlag = Boolean(!this.disableSimilarAdd && !(Number(this.item?.is_on_sale) === 0 || Number(this.item?.stock) === 0) && showWishlistSimilarType !== 'close' && this.column === 2 && this.saveTotal > 10 && !this.constantData.IS_RW)
      let res = configFlag && this.isShowRecommendSimilarAdd
      return res
    },
    itemCompTag () {
      return this.column === 1 ?
        'OneRowItem' :
        this.config.showItemV3 ? 'ItemV3' : 'MoreRowItemre'
    },
    cardImgCropRate () {
      return this.itemCompTag === 'ItemV3' ? '1-1' : ''
    },
    compStyle () {
      return {
        ...this.cardImgCropRate === '1-1' ? { '--crop-img-fit': 'cover' } : {},
        ...(this.itemCompTag === 'ItemV3' ? { marginBottom: '0.16rem' } : {}),
      }
    },
    stockText () {
      return getStockText({ item: this.item })
    },
    labelCarousel () {
      return getLabelCarousel({ item: this.item })
    },
    getAnalysisData() {
      return {
        id: this.config?.itemDAEventExposeId || '2-3-15',
        code: this.config?.sa?.from || 'wishlistKeyCode',
        prefix: this.config?.sa?.activity_from,
        data: {
          from: 'wishlist',
          activity_from: 'wishlist',
          pageType: 'page_wishlist',
          ...(this.config?.sa || {}),
        }
      }
    },
    labelCarouselInfo () {
      const labelCarousel = getLabelCarousel({ item: this.item })
      // 中间层融合，两个组件共存，融合完成后删除
      return {
        showBff: this.item.bffGoodsInfo && this.item?.pretreatInfo?.popularLabelList?.length > 0,
        showWeb: !this.item.bffGoodsInfo && labelCarousel
      }
    },
  },
  mounted() {
    this.getCropRate(this.item)
  },
  methods: {
    // 是否满足相似推荐
    recommendPopupIsVisible(item) {
      let { WishlistsoldoutSimilar } = this.listAbtResult
      let showWishlistSimilar = WishlistsoldoutSimilar?.p?.ShowWishlistSimilar === 'show'
      return this.isProductSoldOut(item) && showWishlistSimilar && !this.constantData.IS_RW && this.column === 2
    },
    // 商品条目是否售罄
    isProductSoldOut(product) {
      return Number(product.is_on_sale) === 0 || Number(product.stock) === 0
    },
    getItemCollection ({ item }) {
      return getItemCollection({ showCollectNum: this.showCollectNum, wishVal: item.count })
    },
    getVisitorText ({ item }) {
      return getVisitorText({ item, language: this.language })
    },
    hasVisitorLabel ({ item }) {
      return hasVisitorLabel({ item })
    },
    getReducePrice ({ item }) {
      return getReducePriceLabel({ item })
    },
    /* 点击更多 */ 
    handleMoreBtn({ item, index, isShow }) {
      // 如果当前展示了more btn操作面板，这个时候isShow为false，取消展示时，不需要弹起点推弹窗
      if(!isShow) {
        analysis.clickMove({
          goods_id: item.goods_id,
          item_sold_out: Number(!item.is_on_sale),
          activity_from: this.config.sa?.activity_from,
        })
        return 
      }
      // 相似弹窗
      if (this.showSimilarRecommend(item)) {
        const recommendPopupRefB = this.$refs?.[`recommendPopupB_${index}`]
        const recommendPopupInstanceB = recommendPopupRefB?.[0] || recommendPopupRefB
        this.$emit('update:similarPopupClass', `show-delete_${item.goods_id}`, !!recommendPopupInstanceB?.show)
        recommendPopupInstanceB?.showRecommendRecPopup({ show: !recommendPopupInstanceB?.show })
      }
      // 同类弹窗
      if(this.watSearchSliderVisible) {
        // 处理点击更多按钮同类推荐弹窗展示问题
        const recommendPopupRef = this.$refs?.[`recommendPopup_${index}`]
        const recommendPopupInstance = recommendPopupRef?.[0] || recommendPopupRef
        recommendPopupInstance?.showRecommendRecPopup({ show: !isShow })
      }
      //实时反馈弹窗
      if(this.recommendPopupVisible){
        const recommendPopupFeedbackRef = this.$refs?.[`recommendPopup`]
        const recommendPopupFeedbackInstance = recommendPopupFeedbackRef?.[0] || recommendPopupFeedbackRef
        recommendPopupFeedbackInstance?.showRecommendRecPopup({ show: !isShow })
      }
      analysis.clickMove({
        goods_id: item.goods_id,
        item_sold_out: Number(!item.is_on_sale),
        activity_from: this.config.sa?.activity_from,
      })
    },

    // 一行一图滑动完成
    itemSlideDone(item, slideDoneCount) {
      analysis.slideDeleteShow({ goods_id: item.goods_id, count: slideDoneCount })
    },
    
    async showRecPopup(item, index) {
      const recommendPopupRef = this.$refs?.[`recommendPopupB_${index}`]
      const recommendPopupInstance = recommendPopupRef?.[0] || recommendPopupRef
      
      recommendPopupInstance?.showRecommendRecPopup({ show: true })
      await recommendPopupInstance?.$nextTick()
      recommendPopupInstance?.initAnalysis()
    },
    getCropRate(item) {
      const productItemRef = this.$refs?.[`productItem_${item.goods_id}`]
      const productItemInstance = productItemRef?.[0] || productItemRef
      if (!productItemInstance) return
      const productEl = productItemInstance?.$el
      const targetEl = productEl?.getElementsByClassName('crop-image-container')?.[0]
      this.cropRateItem = closestRatioHeight(targetEl?.getAttribute('data-crop-rate'))
    },
    // 是否展示相似推荐弹窗
    showSimilarRecommend(item) {
      return !!this.similarRecommendData?.[item.goods_id]?.length || !!item?.similar_products?.length
    },
    // 是否是合规价站点 -找相似弹窗使用
    isSuggested(item) { 
      if(!this.showSimilarRecommend(item)) return false
      return !!item?.pretreatInfo?.suggestedSalePriceConfigInfo?.config?.hideTargetBySuggestedSalePrice
    },
  }
}
</script>
